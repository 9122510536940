import * as types from '../constants/actionTypes';
import { getStateFailure, getStateRequest, getStateSuccess } from '../helpers/getState';

const initialState = {
  token: localStorage.getItem('token'),
  loggedIn: false,
  loading: false,
  error: null,
  isUserLoaded: false,
};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case types.LOGOUT: {
      localStorage.clear();
      sessionStorage.clear();
      return { loggedIn: false };
    }
    
    case types.LOGIN_REQUEST: {
      return getStateRequest(state, { loggedIn: false, isUserLoaded: false });
    }
    
    case types.LOGIN_SUCCESS: {
      const { access_token } = action.payload;;
      // const token = user_token && user_token.access_token;
      
      localStorage.setItem('token', access_token);
      // sessionStorage.setItem('session_id', uuid());
      
      return getStateSuccess(state, {
        loggedIn: true,
        isUserLoaded: true,
        access_token
      });
    }
    
    case types.LOGIN_FAILURE:
      return getStateFailure(state, { loggedIn: false, error: action.payload, isUserLoaded: true });
    
    default:
      return state;
  }
}
