import { createAction } from 'redux-actions';
import * as types from '../constants/actionTypes';
import * as api from '../api';

export const logout = createAction(types.LOGOUT);
export const loginRequest = createAction(types.LOGIN_REQUEST);
export const loginSuccess = createAction(types.LOGIN_SUCCESS);
export const loginFailure = createAction(types.LOGIN_FAILURE);

export const login = (data) => (dispatch) => {
  dispatch(loginRequest());
  
  return api.auth(data)
    .then((response) => {
      dispatch(loginSuccess(response));
      return response;
    })
    .catch((error) => {
      dispatch(loginFailure(error));
      
      return Promise.reject(error);
    });
};