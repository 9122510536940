import errors from '../constants/errors';
import getRedirectUrlToCurrentPage from './redirectCurrentPage';

const transformErrorsObject = (errors = {}) =>
  Object.keys(errors).reduce((acc, item) => {
    if (Array.isArray(errors[item])) {
      const [errorMessage] = errors[item];
      
      acc[item] = errorMessage;
    } else {
      acc[item] = errors[item];
    }
    
    return acc;
  }, {});

export const errorHandlers = async (response) => {  
  const error = await response;

  if (typeof error === 'object' && error.statusCode) {
    const errorObject = {
      status: error.statusCode,
      message: error.message,
      ...transformErrorsObject(error.errors),
    };
    
    return Promise.reject(errorObject);
  }
  
  return Promise.reject(error);
};

export const responseHandler = ({ method, json }) => (response) => {
  if (response.status === 401 || response.status === 403) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = getRedirectUrlToCurrentPage();
  }

  if (errors[response.status]) {
    return Promise.reject(response.json());
  }
  
  if (method === 'delete') {
    return response;
  }
  
  if (json && method !== 'delete') {
    return response.json();
  }
  
  return response;
};
