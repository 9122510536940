import React, { useEffect, useState } from 'react';
import moment from 'moment'
import ReactAudioPlayer from "react-audio-player";
import { getMediaFile, getMediaFileContent } from "../../api";

const Media = () => {
  const [mediaData, setMediaData] = useState(null);
  const [sound, setSound] = useState('');
  const params = new URLSearchParams(window.location.search);
  const mediaPath = params.get('file_uuid');

  useEffect(() => {
    getMediaFile(mediaPath)
      .then((data) => {
        setMediaData(data);

        if (localStorage.getItem(data.file_name)) {
          setSound(localStorage.getItem(data.file_name));
          return null;
        }

        getMediaFileContent(data.links[1].href)
          .then((response) => response.blob())
          .then((body) => {
            const blob = new Blob([body], { type: "audio/wav" });
            console.log(blob);
            const reader = new FileReader();

            reader.onload = function(e) {
              localStorage.setItem(data.file_name, e.target.result);
              setSound(e.target.result);
            };

            reader.readAsDataURL(blob);
          });
      });
  }, []);

  return (
    <div>
      {mediaData && (
        <div className="media-info-container">
          <h3>
            <p>Media Info</p>
          </h3>
          <p>
            <b>Name: </b>
            {mediaData.file_name}
          </p>
          <p>
            <b>Size: </b>
            {mediaData.file_size === 0 ? '0 KB' : `${mediaData.file_size / 1000} KB`}
          </p>
          <p>
            <b>Created Date: </b>
            {moment(mediaData.audit.created_date).format('DD/MM/YYYY hh:mm A')}
          </p>
        </div>
      )}
      <ReactAudioPlayer
        src={sound}
        autoPlay
        controls
      />
    </div>
  );
};

export default Media;