import React, {useEffect, useState} from 'react';
import ReactAudioPlayer from "react-audio-player";
// import { getApiDazeMedia } from "../../api";

const Home = () => {
  const [mediaData] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const mediaPath = params.get('file_uuid') || params.get('file_name');

  useEffect(() => {
    // getApiDazeMedia(mediaPath)
    //   .then(([response]) => {
    //     setMediaData(response);
    //   });
  }, []);

  const ApiDazeMediaLink = `https://us-west-2-recordings.s3-us-west-2.amazonaws.com/4160/${mediaPath}`;

  return (
    <div>
      {mediaData && (
        <div className="media-info-container">
          <h3>Media Info</h3>
          <p>
            <b>Name: </b>
            {mediaData.name}
          </p>
          <p>
            <b>Size: </b>
            {mediaData.size}
          </p>
          <p>
            <b>Modified: </b>
            {mediaData.modified}
          </p>
        </div>
      )}
      <ReactAudioPlayer
        src={ApiDazeMediaLink}
        autoPlay
        controls
      />
    </div>
  );
};

export default Home;