import React from 'react';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import ApiDaze from './ApiDaze';
import Media from './MediaApi';
import { login } from '../actions';

import '../App.css';
import useLogin from './useLogin';

const App = ({ login }) => {
  const { loggedIn } = useLogin({ login });

  if (!loggedIn) {
    return null;
  }
  
  return (
  <Router>
    <div className="App">
      <Container className="mt-5 app-sound-container">
        <Routes>
          <Route exact path="/" element={<ApiDaze />} />
          <Route path="/media" element={<Media />} />
        </Routes>
      </Container>
    </div>
  </Router>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
});

const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(App);
