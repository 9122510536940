import { useEffect, useState } from 'react';
import qs from 'qs';
import getRedirectUrlToCurrentPage from '../helpers/redirectCurrentPage';

export default function useLogin({ login }) {
  const [loggedIn, setLoggedIn] = useState(Boolean(localStorage.getItem('token')));
  const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const loginRequest = () => {
    login({
      code: urlParams.code,
      clientId: process.env.REACT_APP_CLIENT_ID,
      grant_type: 'authorization_code',
    })
      .then(() => {
        setLoggedIn(true);
        window.location.href = getRedirectUrlToCurrentPage();
      })
      .catch(() => {
        if (!localStorage.getItem('token')) {
          redirectToAuthPage();
        }

        window.location.href = getRedirectUrlToCurrentPage();
      });
  };

  const redirectToAuthPage = () => {
    window.location.href = `${process.env.REACT_APP_AUTH_URL}${qs.stringify(
      {
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: getRedirectUrlToCurrentPage(),
        scope: 'openid',
        response_type: 'code',
        state: {
          "app": "starpaas",
        },
      },
      { addQueryPrefix: true }
    )}`;
  }
  
  useEffect(() => {
    if (!localStorage.getItem('token') && !urlParams.code) {
      redirectToAuthPage();
      return null;
    }

    if (urlParams.code) {
      loginRequest();
      return null;
    } 
  }, []);

  return {
    loggedIn
  }
};