// eslint-disable-next-line import/no-anonymous-default-export
export default {
  400: 'Bad request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not found',
  409: 'Conflicts',
  429: 'You send requests too often',
  500: 'Error. Please try again later',
  504: 'Request time expired. Reload this Page',
};
