export function getStateRequest(state, payload = {}) {
  return { ...state, ...payload, error: null, loading: true };
}

export function getStateSuccess(state, payload) {
  return { ...state, ...payload, loading: false, error: null };
}

export function getStateFailure(state, payload) {
  return { ...state, ...payload, loading: false };
}
