import formurlencoded from 'form-urlencoded';
import { BASE_API_URL, AUTH_URL } from './constants';
import { errorHandlers, responseHandler } from './helpers/requestHandlers';

const request = ({
  url,
  body,
  endpoint = '/',
  method = 'get',
  contentType = 'application/json',
  json = true,
  authorization = true,
  stringify = true,
  isHeadersAllowed = true,
  xApiVersionAllowed = true,
  binary = false,
  headers = {}
}) => {
  const URL = url || BASE_API_URL + endpoint;
  
  let RequestHeaders = {};

  if (contentType) {
    RequestHeaders['Content-Type'] = contentType;
  }

  if (xApiVersionAllowed) {
    RequestHeaders['x-api-version'] = 'v1';
  }

  if (authorization) {
    RequestHeaders['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  }

  if (headers) {
    RequestHeaders = { ...RequestHeaders, ...headers };
  }
  
  const requestSettings = {
    method,
    binary,
    headers: isHeadersAllowed ? RequestHeaders : new Headers(),
    body: typeof body === 'string' || !stringify ? body : JSON.stringify(body),
    redirect: 'follow',
  };
  
  return window.fetch(URL, requestSettings).then(responseHandler({ method, json })).catch(errorHandlers);
};

export const auth = (data) => {
  return request({
    method: 'post',
    url: AUTH_URL,
    authorization: false,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${process.env.REACT_APP_BASIC_TOKEN}`,
    },
    xApiVersionAllowed: false,
    body: formurlencoded(data),
  });
};

export const getApiDazeMedia = (mediaPath = '') => {
  return request({
    method: 'get',
    authorization: false,
    xApiVersionAllowed: false,
    url: `https://cpaas-api.voipinnovations.com/gmv2gics/recordings?api_secret=488cee387bd8c59691195865a532c889&details=true&filter=${mediaPath}`,
  });
}

export const getMediaFile = (file_uuid) => {
  return request({
    method: 'get',
    url: `${process.env.REACT_APP_BASE_API_URL}/media/media/${file_uuid}?fields=all&content_url=true`
  })
};

export const getMediaFileContent = (url) => {
  return request({
    method: 'get',
    authorization: false,
    json: false,
    binary: true,
    contentType: 'application/octet-stream',
    url
  })
};